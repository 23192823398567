import {useState, useEffect} from "react";
import Head from 'next/head'
import supportsWebP from 'supports-webp';
import AOS from "aos";

export default function MainLayout(
    {
        children,
        title,
        alternate,
        description,
        canonical,
        loaded
    }) {

    const [origin, setOrigin] = useState('')
    const [host, setHost] = useState('')

    useEffect(() => {

        setOrigin(window.location.origin)
        setHost(window.location.host)

        supportsWebP.then(supported => {
            if (supported) {
                document.body.classList.add('webp')
            }
        });
    }, [])

    useEffect(() => {
        if(loaded) {
            AOS.init({
                once: true
            });
        }
    }, [loaded])

    return (
        <>
            <Head>
                <title>{title}</title>
                <link rel="icon" href={`/img/icons/favicon.png`}/>
                <link rel="apple-touch-icon" sizes="180x180" href={`/img/icons/apple-touch-icon.png`}/>
                <link rel="icon" type="image/png" sizes="32x32" href={`/img/icons/favicon-32x32.png`}/>
                <link rel="icon" type="image/png" sizes="16x16" href={`/img/icons/favicon-16x16.png`}/>
                <link rel="mask-icon" href={`/img/icons/safari-pinned-tab.svg" color="#fc5a57`}/>
                <meta name="msapplication-TileColor" content="#da532c"/>
                <meta name="theme-color" content="#ffffff"/>
                <meta key="description" name="description" content={description}/>
                <link rel="canonical" href={canonical}/>
                <link rel="alternate" hrefLang="en"
                      href={alternate ? `https://shibabudz.org/${alternate}` : 'https://shibabudz.org'}/>
                <link rel="alternate" hrefLang="de"
                      href={alternate ? `https://shibabudz.org/de/${alternate}` : 'https://shibabudz.org/de'}/>
                <link rel="alternate" hrefLang="es"
                      href={alternate ? `https://shibabudz.org/es/${alternate}` : 'https://shibabudz.org/es'}/>
                <link rel="alternate" hrefLang="pt"
                      href={alternate ? `https://shibabudz.org/pt/${alternate}` : 'https://shibabudz.org/pt'}/>
                <link rel="alternate" hrefLang="tr"
                      href={alternate ? `https://shibabudz.org/tr/${alternate}` : 'https://shibabudz.org/tr'}/>
                <link rel="alternate" hrefLang="it"
                      href={alternate ? `https://shibabudz.org/it/${alternate}` : 'https://shibabudz.org/it'}/>
                <link rel="alternate" hrefLang="fr"
                      href={alternate ? `https://shibabudz.org/fr/${alternate}` : 'https://shibabudz.org/fr'}/>
                <link rel="alternate" hrefLang="x-default"
                      href={alternate ? `https://shibabudz.org/${alternate}` : 'https://shibabudz.org'}/>
                <meta property="og:url" content={origin}/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content=''/>
                <meta property="og:description" content=''/>
                <meta property="og:image" content={`${origin}/img/main/og.png`}/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta property="twitter:domain" content={host}/>
                <meta property="twitter:url" content={host}/>
                <meta name="twitter:title" content=''/>
                <meta name="twitter:description" content=''/>
                <meta name="twitter:image" content={`${origin}/img/main/og.png`}/>
                <meta name='description' content={description}/>
            </Head>
            <>
                {children}
            </>
        </>
    )
}