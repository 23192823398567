import React from 'react';

const MainButton = (props) => {

    const {text, data_aos, data_aos_delay, data_aos_easing, data_aos_duration, onClick, disabled, isLoading, type} = props;

    return (
        <button
            type={type}
            disabled={disabled}
            onClick={onClick}
            data-aos={data_aos} data-aos-duration={data_aos_duration} data-aos-easing={data_aos_easing}
            data-aos-delay={data_aos_delay}
            className='main_button'
        >
            {text}
            {isLoading ? <svg viewBox="0 0 800 800" xmlns="http://www.w3.org/2000/svg">
                <circle className="spin" cx="400" cy="400" fill="none"
                        r="200" strokeWidth="50" stroke="#000"
                        strokeDasharray="1098 1400"
                        strokeLinecap="round"/>
            </svg> : null}
        </button>
    );
};

export default MainButton;