import React from "react";
import Link from "next/link";
import {useRouter} from "next/router";

function ChangeLanguage({locales}) {
    const router = useRouter();

    return (
        <ul className='flags_list'>
            <li className='flags_current'>
                 <div className='flags_current_flag_img'><img width='40' height='20' alt={`${router.locale} language`} className='curr_img' src={`/img/flags/${router.locale}.svg`}/></div>
                <span style={{textTransform: 'uppercase', marginLeft: '12px'}}>{router.locale}</span>

                <div className="flags_hidden_wrapper">
                    <ol className='flags_hidden'>
                        {router.locales.map((locale) => (
                            <li key={locale}>
                                <Link href={router.asPath} locale={locale}>
                                    <span className='href_flags'>
                                        <div
                                            className='flags_hidden_item'
                                        >
                                            <div className='flags_hidden_item_icon'>
                                                <img src={`/img/flags/${locale}.svg`} alt={locale + ' Flag'}/>
                                            </div>
                                            <span>{locales[locale]}</span>
                                        </div>
                                    </span>
                                </Link>
                            </li>
                        ))}
                    </ol>
                </div>

            </li>
        </ul>
    );
}

export default ChangeLanguage;